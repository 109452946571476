var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0cafc9fd0def06713417cc351ccdad57a5879d14"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_SAMPLE_RATE = process.env.SENTRY_SAMPLE_RATE ?? 1.0
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_DEPLOY_ENV =
  process.env.SENTRY_DEPLOY_ENV || process.env.NEXT_PUBLIC_SENTRY_DEPLOY_ENV

Sentry.init({
  environment: SENTRY_DEPLOY_ENV,
  dsn:
    SENTRY_DSN ||
    'https://b7ec3c9678154feca9b530a0cb569e90@o83312.ingest.sentry.io/5716022',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  sampleRate: SENTRY_SAMPLE_RATE,
  // filter out events with retryable error
  beforeSend(event) {
    if (!event?.extra?.ok && event?.extra?.response?.retryable) {
      return null
    }
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
