const order = (fetcher) => ({
  get: (id) => fetcher(`/me/orders/${id}`),
  refund: (id, body) =>
    fetcher(`/me/orders/${id}/refund`, { method: 'POST', body }),
  earlyCancellation: (id) =>
    fetcher(`/me/orders/${id}/refund/early_cancellation`, {
      method: 'POST',
    }),
  cancel: (id, amount, currency) =>
    fetcher(`/me/orders/${id}/refund/cancel`, {
      method: 'POST',
      body: {
        amount,
        currency,
      },
    }),
  getRefundableAmount: (id) =>
    fetcher(`/me/orders/${id}/refund/get_refundable_amount`),
  invite: (id, { passengerId, email }) =>
    fetcher(`/me/orders/${id}/invitations`, {
      method: 'POST',
      body: { invitation: { passenger_id: passengerId, email } },
    }),
  acceptInvitation: (token) =>
    fetcher(`/me/invitations/${token}/accept`, { method: 'POST' }),

  /**
   * Update the document for a passenger on an order
   *
   * @param {string} id the order Id
   * @param {string} passengerId the passenger to udpate the document for
   * @param {object} document the document to update
   */
  updateIdentityDocument: (
    id,
    passengerId,
    { type, number, expiration, countryCode }
  ) => {
    return fetcher(
      `/me/orders/${id}/passengers/${passengerId}/identity_documents`,
      {
        method: 'POST',
        body: {
          identity_document: {
            type,
            expires_on: expiration,
            country_code: countryCode,
            unique_identifier: number,
          },
        },
      }
    )
  },

  /**
   * Returns an invoice for the given order and checkout session
   *
   * @param {string} id the order Id
   */
  getInvoice: (id, checkoutSessionId) =>
    fetcher(`/me/orders/${id}/invoices/${checkoutSessionId}`, {
      binary: true,
    }),

  /**
   * Create an invoice for the given order and checkout session
   *
   * @param {string} id the order Id
   * @param {string} checkoutSessionId the checkout session Id
   * @param {object} billingInfo the billing info
   */
  createInvoice: (id, checkoutSessionId, billingInfo) =>
    fetcher(`/me/orders/${id}/invoices/${checkoutSessionId}`, {
      method: 'POST',
      body: {
        ...billingInfo,
      },
    }),
})

export default order
